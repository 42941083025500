import { useEffect, useReducer } from "react";

const useSessionReducer = (key, reducer, initialState) => {
  // The initial state is empty (flag init not true) to prevent hydration problems
  const [state, dispatch] = useReducer(reducer, {});

  const init = state.init;
  useEffect(() => {
    // When the state is not init, try loading from session or fallback to initialState
    if (!init) {
      const dataString = sessionStorage.getItem(key);
      if (dataString) {
        try {
          const sessionState = JSON.parse(dataString);
          dispatch({ type: "init", payload: sessionState });
          return;
        } catch (e) {
          console.warn("Cannot restore state from session: ", e);
        }
      }
      dispatch({ type: "init", payload: initialState });
    }
  }, [key, initialState, init]);

  useEffect(() => {
    // When the state has been initialized, persist it in session, except for the submit flag,
    // that triggers the api call step
    if (state.init) {
      const { submit, ...persistState } = state;
      sessionStorage.setItem(key, JSON.stringify(persistState));
    }
  }, [state, key]);

  return [state, dispatch];
};

export default useSessionReducer;
