import React, { useMemo, useState, useEffect } from "react";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { totalDetailed } from "../../serverless/iid-api/pricing";
import { formatPrice } from "../utils";

const couponDiscounts = {
  1: "25%",
  2: "50%",
  3: "30%",
};
const specialDiscounts = {
  1: "5%",
  2: "10%",
};

const AcquistaStep4 = ({ state, dispatch }) => {
  const { ticketItem, partecipanti, partecipantList, fatturazione } = state;
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { isSubmitting },
  } = useForm({ defaultValues: state.couponData, reValidateMode: "onChange" });

  const [isValidating, setIsValidating] = useState(false);
  const [couponError, setCouponError] = useState(false);
  const [validCoupon, setValidCoupon] = useState(
    state.couponData?.valid
      ? {
          code: state.couponData.couponCode,
          type: state.couponData.type,
          specialType: state.couponData.special,
        }
      : undefined
  );
  const validateCouponCode = async (code) => {
    setIsValidating(true);
    try {
      const params = new URLSearchParams({ code });
      const response = await fetch("/api/validate-coupon?" + params.toString());
      if (!response.ok) {
        throw new Error(
          `Error calling /api/validate-coupon: ${response.status} ${response.statusText}`
        );
      }
      const data = await response.json();
      const valid = data.valid;
      const special = data.special;

      if (valid) {
        setValidCoupon({ code, type: valid, specialType: special });
        setCouponError(false);
      } else {
        setCouponError(true);
      }
      setIsValidating(false);
    } catch (err) {
      console.error("error: ", err);
    }
    setIsValidating(false);
  };

  const couponCode = watch("couponCode");
  const couponValid = validCoupon && validCoupon.code === couponCode ? validCoupon : null;

  useEffect(() => {
    setCouponError(false);
  }, [couponCode]);

  const totalData = useMemo(() => {
    const enableCoupon = true;
    const specialCoupon = validCoupon && enableCoupon ? validCoupon?.specialType : false;
    const sociohrc = state.user.sociohrc;
    const { total, amountIva, discount } = totalDetailed({
      people: state.partecipantList,
      iva: state.fatturazione.iva,
      coupon: enableCoupon && !validCoupon?.specialType ? validCoupon?.type : false,
      specialCoupon,
      sociohrc,
    });
    return {
      total,
      totalIva: amountIva,
      discount,
      couponCode: enableCoupon ? couponCode : "",
      couponValid: enableCoupon ? validCoupon : false,
      specialCoupon,
      sociohrc,
    };
  }, [state, couponCode, validCoupon]);

  const onSubmit = async (data) => {
    console.log(data);
    dispatch({
      type: "step4",
      payload:
        data.couponCode && data.couponCode === validCoupon?.code
          ? {
              couponCode,
              valid: true,
              type: validCoupon.type,
              special: validCoupon.specialType,
            }
          : undefined,
    });
  };

  const discountPerc = useMemo(() => {
    let discountPerc = 0;
    if (totalData?.discount) {
      console.log("totalData: ", totalData);
      if (totalData?.couponValid && !totalData?.specialCoupon) {
        // Coupon normale
        discountPerc = `${couponDiscounts[totalData?.couponValid?.type]} Coupon`;
      } else if (totalData?.sociohrc && partecipantList.length > 1) {
        // Sconto per socio HRC + multiplo
        discountPerc = "20% Socio HRC + Acquisto multiplo";
      } else if (totalData?.sociohrc) {
        // Sconto per socio HRC
        discountPerc = "20% Socio HRC";
      } else if (partecipantList.length > 1 && !totalData?.specialCoupon) {
        // Sconto per più persone
        discountPerc = "20% Acquisto multiplo";
      } else if (partecipantList.length > 1 && totalData?.specialCoupon) {
        // Sconto per più persone e coupon speciale
        discountPerc = `20% Acquisto multiplo + ${
          specialDiscounts[totalData?.specialCoupon]
        } Coupon`;
      } else if (totalData?.specialCoupon) {
        // Sconto coupon speciale
        discountPerc = `${specialDiscounts[totalData?.specialCoupon]} Coupon`;
      }
    }
    return discountPerc;
  }, [totalData, partecipantList]);

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <div className="box-progress">
            <div className="box-progress__title">Riepilogo ticket</div>
            <div className="box-progress__bar">
              <span className="step4"></span>
            </div>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="form pb-0">
        <div className="row">
          <div className="col-12">
            <div className="d-md-flex mb-5">
              <div className="text-nowrap me-md-5 mt-md-4">Inserisci il codice Coupon</div>
              <div className="form__group flex-fill mb-0">
                <div className="form__wrap-input-text">
                  <input
                    type="text"
                    className="form__input-text"
                    name="couponCode"
                    id="couponCode"
                    maxLength={8}
                    disabled={isSubmitting}
                    placeholder=" "
                    {...register("couponCode")}
                  />
                  <label className="form__label" htmlFor="couponCode">
                    Numero codice
                  </label>
                </div>
                {couponError ? (
                  <span className="d-block form__error">
                    Il codice coupon inserito non è valido.
                  </span>
                ) : null}
                {couponValid ? <span className="d-block form__error">Coupon valido</span> : null}
              </div>
              <div className="ms-md-5 mt-md-2 me-md-5">
                <button
                  type="button"
                  className={classNames("form__btn", {
                    invisible: couponValid,
                  })}
                  disabled={isValidating || isSubmitting}
                  onClick={() => validateCouponCode(getValues("couponCode"))}
                >
                  Valida codice
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="box-recap">
              <div className="box-recap__head d-none d-sm-block">
                <div className="row">
                  <div className="col-12 col-sm-3">Tipologia ticket</div>
                  <div className="col-12 col-sm-3">Quantità</div>
                  <div className="col-12 col-sm-3">Prezzo singolo ticket</div>
                  <div className="col-12 col-sm-3">Prezzo totale</div>
                </div>
              </div>
              <div className="box-recap__row">
                <div className="row">
                  <div className="col-12 col-sm-3">
                    <span className="d-block d-md-none me-2">Tipologia ticket: </span>
                    {`${ticketItem.label} / ${ticketItem.date}`}
                  </div>
                  <div className="col-12 col-sm-3">
                    <span className="d-block d-md-none me-2">Quantità: </span>
                    {partecipanti}
                  </div>
                  <div className="col-12 col-sm-3">
                    <span className="d-block d-md-none me-2">Prezzo singolo ticket: </span>
                    {formatPrice(
                      (ticketItem.discount ? ticketItem.discount : ticketItem.price) * 100
                    )}
                  </div>
                  <div className="col-12 col-sm-3">
                    <span className="d-block d-md-none me-2">Prezzo totale: </span>
                    {formatPrice(
                      (ticketItem.discount ? ticketItem.discount : ticketItem.price) *
                        100 *
                        partecipanti
                    )}
                  </div>
                </div>
              </div>
              {totalData?.discount > 0 && (
                <div className="box-recap__sum">
                  <div className="row">
                    <div className="col-12 col-sm-3 offset-sm-6">Sconto {discountPerc}</div>
                    <div className="col-12 col-sm-3">{formatPrice(totalData.discount)}</div>
                  </div>
                </div>
              )}
              {fatturazione.iva !== "false" && (
                <div className="box-recap__sum">
                  <div className="row">
                    <div className="col-12 col-sm-3 offset-sm-6">IVA</div>
                    <div className="col-12 col-sm-3">{formatPrice(totalData.totalIva)}</div>
                  </div>
                </div>
              )}
              <div className="box-recap__results text-uppercase">
                <div className="row">
                  <div className="col-12 col-sm-3 offset-sm-6">Totale ordine</div>
                  <div className="col-12 col-sm-3">{formatPrice(totalData.total)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form__navigation">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  onClick={() => dispatch({ type: "back" })}
                  className="btn-link btn-link--small btn-link--prev"
                >
                  <span className="btn-link__text">Back</span>
                  <span className="btn-link__circle"></span>
                </button>
                <button
                  type="submit"
                  disabled={isValidating}
                  className="btn-link btn-link--small btn-link--next"
                >
                  <span className="btn-link__circle"></span>
                  <span className="btn-link__text">Prosegui</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AcquistaStep4;
