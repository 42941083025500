import React from "react";
import { useForm } from "react-hook-form";

const AcquistaStep2 = ({ data, dispatch }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: data.partecipante,
  });
  const onSubmit = (data) => {
    console.log(data);
    dispatch({
      type: "step2",
      payload: data,
    });
  };

  const isAzienda = data?.customerType === "azienda";

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <div className="box-progress">
            <div className="box-progress__title">Inserisci i dati dei partecipanti</div>
            <div className="box-progress__bar">
              <span className="step2"></span>
            </div>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="form pb-0">
        <div className="row">
          <div className="col-12">
            <div className="form__info">
              <span className="text-uppercase">Partecipante</span>{" "}
              <span className="number d-inline-block text-center">{data?.partecipante?.index}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form__group">
              <div className="form__wrap-input-text">
                <input
                  type="text"
                  className="form__input-text"
                  name="name"
                  id="name"
                  placeholder=" "
                  {...register("name", {
                    required: {
                      value: true,
                      message: "Specificare un nome.",
                    },
                    pattern: {
                      value: /^[a-zA-Z\s]*$/,
                      message: "Specificare un nome (solo caratteri e/o spazi).",
                    },
                  })}
                />
                <label className="form__label" htmlFor="name">
                  Nome *
                </label>
              </div>
              {errors.name ? (
                <span className="d-block form__error">{errors.name.message}</span>
              ) : null}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form__group">
              <div className="form__wrap-input-text">
                <input
                  type="text"
                  className="form__input-text"
                  name="surname"
                  id="surname"
                  placeholder=" "
                  {...register("surname", {
                    required: {
                      value: true,
                      message: "Specificare un cognome.",
                    },
                    pattern: {
                      value: /^[a-zA-Z\s]*$/,
                      message: "Specificare un cognome (solo caratteri e/o spazi).",
                    },
                  })}
                />
                <label className="form__label" htmlFor="surname">
                  Cognome *
                </label>
              </div>
              {errors.surname ? (
                <span className="d-block form__error">{errors.surname.message}</span>
              ) : null}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form__group">
              <div className="form__wrap-input-text">
                <input
                  type="text"
                  className="form__input-text"
                  name="company"
                  id="company"
                  placeholder=" "
                  {...register("company", {
                    required: {
                      value: isAzienda,
                      message: "Specificare l'azienda.",
                    },
                  })}
                />
                <label className="form__label" htmlFor="company">
                  Azienda {isAzienda && "*"}
                </label>
              </div>
              {errors.company ? (
                <span className="d-block form__error">{errors.company.message}</span>
              ) : null}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form__group">
              <div className="form__wrap-input-text">
                <input
                  type="text"
                  className="form__input-text"
                  name="role"
                  id="role"
                  placeholder=" "
                  {...register("role", {
                    required: {
                      value: isAzienda,
                      message: "Specificare il ruolo in azienda.",
                    },
                  })}
                />
                <label className="form__label" htmlFor="role">
                  Ruolo in azienda {isAzienda && "*"}
                </label>
              </div>
              {errors.role ? (
                <span className="d-block form__error">{errors.role.message}</span>
              ) : null}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form__group">
              <div className="form__wrap-input-text">
                <input
                  type="email"
                  className="form__input-text"
                  name="email"
                  id="email"
                  placeholder=" "
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Specificare un indirizzo email.",
                    },
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Specificare un indirizzo email valido.",
                    },
                  })}
                />
                <label className="form__label" htmlFor="email">
                  Email *
                </label>
              </div>
              {errors.email ? (
                <span className="d-block form__error">{errors.email.message}</span>
              ) : null}
            </div>
          </div>
        </div>
        <div className="form__navigation">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  className="btn-link btn-link--small btn-link--prev"
                  onClick={() => dispatch({ type: "back" })}
                >
                  <span className="btn-link__text">Back</span>
                  <span className="btn-link__circle"></span>
                </button>
                <button type="submit" className="btn-link btn-link--small btn-link--next">
                  <span className="btn-link__circle"></span>
                  <span className="btn-link__text">Prosegui</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AcquistaStep2;
