import React from "react";

const AcquistaLoading = ({ error, retry }) => {
  return (
    <div className="text-center p-3 p-lg-4">
      {error ? (
        <>
          <p>Ops, siamo spiacenti ma si è verificato un errore.</p>
          <button className="btn-link btn-link--small btn-link--prev mt-4" onClick={retry}>
            <span className="btn-link__circle"></span>
            <span className="btn-link__text">Riprova</span>
          </button>
        </>
      ) : (
        <p>
          Un momento, stiamo elaborando la tua richiesta, verrai reindirizzato automaticamente...
        </p>
      )}

      <div className="box-progress pt-4 px-3 pt-lg-5 px-lg-5 mt-4 mt-lg-5 mx-2 mx-lg-5">
        <div className="box-progress__bar">
          <span className="step6"></span>
        </div>
      </div>
    </div>
  );
};

export default AcquistaLoading;
