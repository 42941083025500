import React from "react";
import { useForm } from "react-hook-form";

const AcquistaStep3 = ({ data, dispatch }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...data,
      iva: data?.iva || "true",
      privacy: data?.privacy ?? true,
    },
    mode: "onChange",
  });
  const onSubmit = (data) => {
    console.log(data);
    dispatch({
      type: "step3",
      payload: data,
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <div className="box-progress">
            <div className="box-progress__title">Dati di fatturazione</div>
            <div className="box-progress__bar">
              <span className="step3"></span>
            </div>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="form pb-0">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form__group">
              <div className="form__wrap-input-text">
                <input
                  type="text"
                  className="form__input-text"
                  name="businessName"
                  id="businessName"
                  placeholder=" "
                  {...register("businessName", {
                    required: {
                      value: true,
                      message: "Specificare la ragione sociale.",
                    },
                  })}
                />
                <label className="form__label" htmlFor="businessName">
                  Ragione sociale *
                </label>
              </div>
              {errors.businessName ? (
                <span className="d-block form__error">{errors.businessName.message}</span>
              ) : null}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form__group">
              <div className="form__wrap-input-text">
                <input
                  type="text"
                  className="form__input-text"
                  name="street"
                  id="street"
                  placeholder=" "
                  {...register("street", {
                    required: {
                      value: true,
                      message: "Specificare un indirizzo.",
                    },
                  })}
                />
                <label className="form__label" htmlFor="indirizzo">
                  Indirizzo *
                </label>
              </div>
              {errors.street ? (
                <span className="d-block form__error">{errors.street.message}</span>
              ) : null}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6">
                <div className="form__group">
                  <div className="form__wrap-input-text">
                    <input
                      type="text"
                      className="form__input-text"
                      name="number"
                      id="number"
                      placeholder=" "
                      {...register("number", {
                        required: {
                          value: true,
                          message: "Specificare il numero civico.",
                        },
                      })}
                    />
                    <label className="form__label" htmlFor="number">
                      Numero *
                    </label>
                  </div>
                  {errors.number ? (
                    <span className="d-block form__error">{errors.number.message}</span>
                  ) : null}
                </div>
              </div>
              <div className="col-6">
                <div className="form__group">
                  <div className="form__wrap-input-text">
                    <input
                      type="text"
                      className="form__input-text"
                      name="cap"
                      id="cap"
                      placeholder=" "
                      {...register("cap", {
                        required: {
                          value: true,
                          message: "Specificare il CAP.",
                        },
                      })}
                    />
                    <label className="form__label" htmlFor="cap">
                      CAP *
                    </label>
                  </div>
                  {errors.cap ? (
                    <span className="d-block form__error">{errors.cap.message}</span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form__group">
              <div className="form__wrap-input-text">
                <input
                  type="text"
                  className="form__input-text"
                  name="city"
                  id="city"
                  placeholder=" "
                  {...register("city", {
                    required: {
                      value: true,
                      message: "Specificare il comune.",
                    },
                  })}
                />
                <label className="form__label" htmlFor="city">
                  Comune *
                </label>
              </div>
              {errors.city ? (
                <span className="d-block form__error">{errors.city.message}</span>
              ) : null}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form__group">
              <div className="form__wrap-input-text">
                <input
                  type="text"
                  className="form__input-text"
                  name="province"
                  id="province"
                  placeholder=" "
                  {...register("province", {
                    required: {
                      value: true,
                      message: "Specificare la provincia.",
                    },
                  })}
                />
                <label className="form__label" htmlFor="province">
                  Provincia *
                </label>
              </div>
              {errors.province ? (
                <span className="d-block form__error">{errors.province.message}</span>
              ) : null}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form__group">
              <div className="form__wrap-input-text">
                <input
                  type="text"
                  className="form__input-text"
                  name="partitaIva"
                  id="partitaIva"
                  placeholder=" "
                  {...register("partitaIva", {
                    required: {
                      value: true,
                      message: "Specificare la partita IVA.",
                    },
                  })}
                />
                <label className="form__label" htmlFor="partitaIva">
                  Partita IVA *
                </label>
              </div>
              {errors.partitaIva ? (
                <span className="d-block form__error">{errors.partitaIva.message}</span>
              ) : null}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form__group">
              <div className="form__wrap-input-text">
                <input
                  type="text"
                  className="form__input-text"
                  name="codiceFatturazione"
                  id="codiceFatturazione"
                  placeholder=" "
                  {...register("codiceFatturazione", {
                    required: {
                      value: false,
                      message: "Specificare il codice unico di fatturazione.",
                    },
                  })}
                />
                <label className="form__label" htmlFor="codiceFatturazione">
                  Codice unico di fatturazione
                </label>
              </div>
              {errors.codiceFatturazione ? (
                <span className="d-block form__error">{errors.codiceFatturazione.message}</span>
              ) : null}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form__group">
              <div className="form__wrap-input-text">
                <input
                  type="text"
                  className="form__input-text"
                  name="fiscalCode"
                  id="fiscalCode"
                  placeholder=" "
                  {...register("fiscalCode", {
                    required: {
                      value: true,
                      message: "Specificare il codice fiscale.",
                    },
                  })}
                />
                <label className="form__label" htmlFor="fiscalCode">
                  Codice fiscale *
                </label>
              </div>
              {errors.fiscalCode ? (
                <span className="d-block form__error">{errors.fiscalCode.message}</span>
              ) : null}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form__group">
              <div className="form__wrap-input-text">
                <input
                  type="email"
                  className="form__input-text"
                  name="email"
                  id="email"
                  placeholder=" "
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Specificare un indirizzo email.",
                    },
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Specificare un indirizzo email valido.",
                    },
                  })}
                />
                <label className="form__label" htmlFor="email">
                  Email *
                </label>
              </div>
              {errors.email ? (
                <span className="d-block form__error">{errors.email.message}</span>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="d-md-flex">
                <div className="me-md-5">Trattamento dell'IVA *</div>
                <div className="d-flex">
                  <div>
                    <label className="radio">
                      <div>Si</div>
                      <input
                        type="radio"
                        name="iva"
                        value="true"
                        className="radio__input"
                        {...register("iva", { required: true })}
                      />
                      <span className="radio__mark"></span>
                    </label>
                  </div>
                  <div>
                    <label className="radio">
                      <div>No</div>
                      <input
                        type="radio"
                        name="iva"
                        value="false"
                        className="radio__input"
                        {...register("iva", { required: true })}
                      />
                      <span className="radio__mark"></span>
                    </label>
                  </div>
                  <div>
                    <label className="radio">
                      <div>Split Payment</div>
                      <input
                        type="radio"
                        name="iva"
                        value="splitpayment"
                        className="radio__input"
                        {...register("iva", { required: true })}
                      />
                      <span className="radio__mark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form__group">
              <label className="checkbox">
                <div>Confermo di aver preso visione dell'informativa sul trattamento dei dati</div>
                <input
                  type="checkbox"
                  name="privacyInfo"
                  id="privacyInfo"
                  className="checkbox__input"
                  {...register("privacyInfo", {
                    required: {
                      value: true,
                      message: "Confermare la presa visione dell'informativa.",
                    },
                  })}
                />
                <span className="checkbox__mark"></span>
              </label>
              {errors.privacyInfo ? (
                <span className="d-block form__error">{errors.privacyInfo.message}</span>
              ) : null}
            </div>
          </div>
          <div className="col-12">
            <div className="form__group">
              <label className="checkbox">
                <div>
                  Acconsento al trattamento dei miei dati per l'invio di informazioni sull'evento e
                  per gli usi amministrativi
                </div>
                <input
                  type="checkbox"
                  name="privacyData"
                  id="privacyData"
                  className="checkbox__input"
                  {...register("privacyData", {
                    required: {
                      value: true,
                      message: "E' obbligatorio acconsentire al trattamento del dati.",
                    },
                  })}
                />
                <span className="checkbox__mark"></span>
              </label>
              {errors.privacyData ? (
                <span className="d-block form__error">{errors.privacyData.message}</span>
              ) : null}
            </div>
          </div>
        </div>
        <div className="form__navigation">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  onClick={() => dispatch({ type: "back" })}
                  className="btn-link btn-link--small btn-link--prev"
                >
                  <span className="btn-link__text">Back</span>
                  <span className="btn-link__circle"></span>
                </button>
                <button type="submit" className="btn-link btn-link--small btn-link--next">
                  <span className="btn-link__circle"></span>
                  <span className="btn-link__text">Prosegui</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AcquistaStep3;
