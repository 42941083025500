import React, { useEffect } from "react";
import { graphql, navigate } from "gatsby";
import useSessionReducer from "../../hooks/use-session-reducer";
import AcquistaLayout from "../../components/acquista-layout";
import AcquistaStep1 from "../../components/acquista-step-1";
import AcquistaStep2 from "../../components/acquista-step-2";
import AcquistaStep3 from "../../components/acquista-step-3";
import AcquistaStep4 from "../../components/acquista-step-4";
import AcquistaStep5 from "../../components/acquista-step-5";
import AcquistaLoading from "../../components/acquista-loading";

const formStepsReducer = (state, action) => {
  const { step, partecipante, partecipanti, partecipantList = [], ticketItem } = state;
  switch (action.type) {
    case "init":
      return {
        ...action.payload,
        init: true,
      };
    case "step1":
      const { partecipanti: nPartecipanti, ...user } = action.payload;
      return {
        ...state,
        user,
        partecipanti: nPartecipanti,
        partecipante: partecipantList[0] || { index: 1, id: ticketItem.id },
        partecipantList: partecipantList.slice(0, nPartecipanti),
        step: 2,
      };
    case "back":
      return {
        ...state,
        partecipante:
          step === 2
            ? partecipante?.index > 1
              ? partecipantList[partecipante.index - 2]
              : step > 2
              ? partecipantList[partecipante.index]
              : null
            : partecipante,
        step: step === 2 ? (partecipante?.index > 1 ? 2 : 1) : step - 1,
      };
    case "step2":
      return {
        ...state,
        partecipantList:
          partecipantList.length >= partecipante.index
            ? // Replace partecipant data at the right index and keep the others
              [
                ...partecipantList.slice(0, partecipante.index - 1),
                action.payload,
                ...partecipantList.slice(partecipante.index),
              ]
            : // Add new partecipant
              partecipantList.concat(action.payload),
        partecipante:
          partecipante.index < partecipanti
            ? partecipantList[partecipante.index] || {
                index: partecipante.index + 1,
                id: ticketItem.id,
              }
            : action.payload,
        step: partecipante.index < partecipanti ? 2 : 3,
      };
    case "step3":
      return {
        ...state,
        fatturazione: action.payload,
        step: 4,
      };
    case "step4":
      return {
        ...state,
        couponData: action.payload,
        step: 5,
      };
    case "step5":
      return {
        ...state,
        payment: action.payload.payment,
        submit: true,
      };
    case "submitError":
      return {
        ...state,
        submitError: true,
        submit: false,
      };
    case "retry":
      return {
        ...state,
        submitError: false,
      };
    default:
      return state;
  }
};

const AcquistaPage = ({ data, location }) => {
  const { ticketId = "day1-2" } = location.state || {};
  const ticketItem = data.ticketsJson.tickets.find((item) => item.id === ticketId);
  const [state, dispatch] = useSessionReducer("iid-form-acquista", formStepsReducer, {
    step: location.state?.step || 1,
    ticketItem,
  });
  const {
    init,
    step,
    user,
    partecipanti,
    partecipante,
    fatturazione,
    payment,
    submit,
    submitError,
  } = state;

  useEffect(() => {
    const { user, partecipantList, fatturazione, couponData, payment, submit } = state;
    if (submit) {
      const checkout = async () => {
        const payload = {
          user,
          people: partecipantList,
          fatturazione,
          couponData,
          success_url: `${window.location.origin}/acquista/grazie-dell-acquisto-card/`,
          cancel_url: window.location.href,
        };
        if (payment === "card") {
          console.log("Create checkout session: ", payload);
          try {
            const response = await fetch("/api/checkout-session", {
              method: "POST",
              body: JSON.stringify(payload),
            });
            if (!response.ok) {
              throw new Error(
                `Error calling /api/checkout-session: ${response.status} ${response.statusText}`
              );
            }
            const data = await response.json();
            window.location.assign(data.redirectUrl);
          } catch (err) {
            dispatch({ type: "submitError" });
            console.error("Error creating checkout session: ", err);
          }
        } else {
          try {
            const response = await fetch("/api/email-bonifico", {
              method: "POST",
              body: JSON.stringify(payload),
            });
            if (!response.ok) {
              throw new Error(
                `Error calling /api/email-bonifico: ${response.status} ${response.statusText}`
              );
            }
            const result = await response.json();
            console.log("result: ", result);
            navigate("/acquista/grazie-dell-acquisto-bonifico/");
          } catch (err) {
            dispatch({ type: "submitError" });
            console.error("Error bonifico: ", err);
          }
        }
      };
      checkout();
    }
  }, [state, dispatch]);

  return (
    <AcquistaLayout>
      <div className="text-center">
        <div className="box-ticket__day">{ticketItem.label}</div>
        <div className="box-ticket__date">{ticketItem.date}</div>
      </div>
      {init ? (
        submit || submitError ? (
          <AcquistaLoading error={submitError} retry={() => dispatch({ type: "retry" })} />
        ) : (
          {
            1: <AcquistaStep1 data={{ ...user, partecipanti }} dispatch={dispatch} />,
            2: (
              <AcquistaStep2
                data={{ partecipante, customerType: user?.customerType }}
                dispatch={dispatch}
                key={partecipante?.index}
              />
            ),
            3: <AcquistaStep3 data={fatturazione} dispatch={dispatch} />,
            4: <AcquistaStep4 state={state} dispatch={dispatch} />,
            5: <AcquistaStep5 data={payment} dispatch={dispatch} />,
          }[step]
        )
      ) : (
        <div></div>
      )}
    </AcquistaLayout>
  );
};

export default AcquistaPage;
export const query = graphql`
  {
    ticketsJson {
      tickets {
        id
        label
        date
        price
        discount
        list {
          name
        }
      }
    }
  }
`;
