import React from "react";
import { useForm } from "react-hook-form";
import Visa from "../icons/cc-visa-brands.svg";
import Mastercard from "../icons/cc-mastercard-brands.svg";
import AmericanExpress from "../icons/cc-amex-brands.svg";
import CreditCard from "../icons/credit-card-regular.svg";

const AcquistaStep5 = ({ data, dispatch }) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      ...data,
      payment: data || "card",
    },
    mode: "onChange",
  });
  const onSubmit = (data) => {
    console.log(data);
    dispatch({
      type: "step5",
      payload: data,
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <div className="box-progress">
            <div className="box-progress__title">Tipologia di pagamento</div>
            <div className="box-progress__bar">
              <span className="step5"></span>
            </div>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="form pb-0">
        <div className="row">
          <div className="col-12">
            <div className="d-md-flex">
              <div>
                <label className="radio">
                  <div>Carte di Credito</div>
                  <input
                    type="radio"
                    name="payment"
                    value="card"
                    className="radio__input"
                    {...register("payment", { required: true })}
                  />
                  <span className="radio__mark"></span>
                </label>
              </div>
              <div className="form__payments d-flex flex-wrap">
                <div>
                  <Visa className="ico-svg" />
                </div>
                <div>
                  <Mastercard className="ico-svg" />
                </div>
                <div>
                  <AmericanExpress className="ico-svg" />
                </div>
                <div>
                  <CreditCard className="ico-svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="d-md-flex">
              <div>
                <label className="radio">
                  <div>Bonifico bancario</div>
                  <input
                    type="radio"
                    name="payment"
                    value="transfer"
                    className="radio__input"
                    {...register("payment", { required: true })}
                  />
                  <span className="radio__mark"></span>
                </label>
              </div>
              <div className="form__payments">
                <div>
                  Istituto bancario: <span className="fw-bold">Unicredit</span>
                </div>
                <div>
                  Conto corrente intestato a:{" "}
                  <span className="fw-bold">Intranet Management srl</span>
                </div>
                <div>
                  IBAN: <span className="fw-bold">IT40B0200873731000105095272</span>
                </div>
                <div>
                  Causale: <span className="fw-bold">IID Milano 2025</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form__navigation">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  onClick={() => dispatch({ type: "back" })}
                  className="btn-link btn-link--small btn-link--prev"
                >
                  <span className="btn-link__text">Back</span>
                  <span className="btn-link__circle"></span>
                </button>
                <button type="submit" className="btn-link btn-link--small btn-link--next">
                  <span className="btn-link__circle"></span>
                  <span className="btn-link__text">
                    Vai al <span className="d-block bold">pagamento</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AcquistaStep5;
